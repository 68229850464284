// import logo from './logo.svg';
import Home from "./components/Home";
function App(props) {

  return (
        <Home  banner1='url("assets/images/banners/b3.webp")' subscriptionbanner = 'url("assets/images/banners/b1.jpg")' contact = '+917012138283'/>
  );
}

export default App;
