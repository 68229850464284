import React from 'react'

function Productcard(props) {
  return (
    
     
            <div class="col-lg-2 col-md-4 col-6">
                <div class="product demo21">
                    <figure class="product-media">
                        
                    <a href={`https://wa.me/+917012138283?text=I am look for products related to " ${props.productname} " . Is it Available Now. I saw this product in Your website.`}>
                            <img src={props.image1url} alt="Product image" class="product-image"/>
                    </a>
                    </figure>
                    <div class="product-body text-center">
                        <div class="product-cat">
                            <a href="#">Tops</a>
                        </div>
                        <h3 class="product-title"><a href="product.html">{props.productname}</a></h3>
                        {/* <div class="product-price">
                            <span class="cur-price">$55.99</span>
                        </div> */}
                        <div class="ratings-container">
                            <div class="ratings">
                                <div class="ratings-val" style={{width: "80%"}}></div>
                            </div>
                            {/* <span class="ratings-text">( 2 Reviews )</span> */}
                        </div>
                        {/* <div class="product-action">
                            <a href="#" class="btn-product btn-cart" title="Add to cart"><span>ADD TO CART</span></a>
                        </div>
                        <a href="#" class="btn-addtolist"><span>&nbsp;Add to Wishlist</span></a> */}

                    </div>
                </div>

            </div>
                          
         
                    
                                    
      )
}

export default Productcard
