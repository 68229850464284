import React from 'react'
import products from '../../src/product_data.json'
import Productcard from '../components/Productcard'
import companies from '../company.json'
function Home(props) {
  return (
    <main class="main" >
            <div class="intro-slider-container mb-5">
                <div class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light" data-toggle="owl" 
                    data-owl-options='{
                        "dots": true,
                        "nav": false, 
                        "responsive": {
                            "1200": {
                                "nav": false,
                                "dots": false
                            }
                        }
                    }'>
                    <div class="intro-slide" style={{backgroundImage:props.banner1}}>
                        <div class="container intro-content">
                            <div class="row">
                                <div class="intro">
                                    <div class="title">
                                        <h3>CelestialAutoCraft</h3>
                                    </div>
                                    <div class="content">
                                        <h3>Where Every Part </h3>
                                        <h3>Drives Quality</h3>
                                    </div>
                                    <div class="price">
                                        {/* <h3>SAVE UP TO 30%</h3> */}
                                        </div>
                                    <div class="action">
                                        <a href="/category" class="btn btn-primary">
                                            <span>DISCOVER NOW</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    
                </div>

                <span class="slider-loader"></span>
            </div>



            <div class="container logos">
                <div class="owl-carousel mb-5 owl-simple" data-toggle="owl" 
                    data-owl-options='{
                        "nav": true, 
                        "dots": false,
                        "margin": 10,
                        "loop": false,
                        "responsive": {
                            "0": {
                                "items":2
                            },
                            "420": {
                                "items":3
                            },
                            "600": {
                                "items":4
                            },
                            "900": {
                                "items":5
                            },
                            "1024": {
                                "items":6
                            }
                        }
                    }'>

                    {
                        companies.map((company,index)=>(
                                <a href="#" class="brand">
                        <img src={company.image1url} alt="Brand Name"/>
                    </a>

                        ))
                    }
                
                  
                </div>
            </div>

            {/* <div class="container bestsellers">
                <hr class="mb-1"/>
                <div class="heading">
                    <h2 class="title text-center">BEST SELLERS</h2>
                    <p class="content text-center mb-4">The Trends Boutique: The latest fashion trends from top brands!</p>
                </div>

                <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                    data-owl-options='{
                        "nav": true, 
                        "dots": false,
                        "margin": 20,
                        "loop": false,
                        "responsive": {
                            "0": {
                                "items":2
                            },
                            "768": {
                                "items":3
                            },
                            "992": {
                                "items":4
                            }
                        }
                    }'>
                    {
                      category.map((product,b)=>(
                        <Categories productname = {product.name} price = {product.price} category={product.category} image1url={product.image1url} image2url={product.image2url}/>
                      ))
                    }
                </div>
            </div>
            */}
           

            <div class="container new-arrivals">
                <hr class="mb-5 mt-1"/>
                <div class="heading heading-center mb-3">
                    <h2 class="title">Our top Product Categories</h2>
                  <p>Latest product  Categories that are available</p>
                </div>
            </div>
            {/* <div class="container new-arrivals"> */}

                <div class="carousel col-lg-12 center" >
                    <div class="row">
                {
                    products.map((product,b)=>(
                        <Productcard productname = {product.name} price = {product.price} category={product.category} image1url={product.image1url} image2url={product.image2url}/>
                    ))
                }
                    </div>
                </div>
{/* </div> */}
            <div class="container newsletter">
                <div class="background" style={{backgroundImage:props.subscriptionbanner}}>
                    <div class="subscribe">
                        <div class="subscribe-title text-center">
                            <h1 class="intro-title2nd">Contact Us For more info</h1>
                            <h1 class="intro-title1st">Learn about new offers and get more deals by joining our newsletter</h1>
                        </div>
                        <form action="#">
                            <div class="input-group">
                               
                                <div class="input-group-append">
                                    <button class="btn btn-subscribe" type="submit"><span>Contact Us at +91 7012138283</span><i class="icon-long-arrow-right"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="container service mt-4">

                <div class="col-sm-6 col-lg-3 col-noPadding">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon text-dark">
                            <i class="icon-truck"></i>
                        </span>
                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Customer &amp; Support</h3>
                            <p>Available at all time</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon text-dark">
                            <i class="icon-rotate-left"></i>
                        </span>

                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Return &amp; Refund</h3>
                            <p>Contact for more info</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon text-dark">
                            <i class="icon-life-ring"></i>
                        </span>

                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Quality Support</h3>
                            <p>Alway online feedback 24/7</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        {/* <span class="icon-box-icon text-dark">
                            <i class="icon-envelope"></i>
                        </span> */}

                        {/* <div class="icon-box-content">
                            <h3 class="icon-box-title">JOIN OUR NEWSLETTER</h3>
                            <p>10% off by subscribing to our newsletter</p>
                        </div> */}
                    </div>
                </div>
            </div>


        </main>
  )
}

export default Home
